<template>
	<!-- eslint-disable vue/no-v-html -->

	<div>
		<div class="detail-iteration">
			<div class="iteration-header">
				<div class="iteration-header-image">
					<b-skeleton
						v-if="!interacao"
						animated
						circle
						width="36px"
						height="36px"
					/>

					<UIImage
						v-else
						size="36:36"
						:src="imagemUrl"
					/>
				</div>

				<div class="iteration-header-group">
					<b-skeleton
						v-if="!interacao"
						animated
						width="12rem"
					/>

					<span
						v-else
						class="iteration-user"
					>
						{{ nome }}
					</span>

					<b-skeleton
						v-if="!interacao"
						animated
						width="10rem"
					/>

					<span
						v-else
						class="iteration-when"
					>
						{{ data }}
					</span>
				</div>
			</div>

			<div class="iteration-main">
				<div v-if="!interacao">
					<b-skeleton
						animated
						height="4rem"
					/>

					<b-skeleton
						animated
						width="16rem"
					/>

					<b-skeleton
						animated
						width="10rem"
					/>
				</div>

				<div
					v-else
					v-html="interacao.reqm_parecer"
				/>
			</div>

			<div
				v-if="anexos.length > 0"
				class="iteration-footer"
			>
				<div class="iteration-footer-title">
					Anexos
				</div>

				<div
					v-for="anexo in anexos"
					:key="anexo.id"
					class="iteration-footer-item"
				>
					<span>{{ anexo.tamanho }}</span>

					<span
						class="iteration-download"
						@click="download(anexo.id)"
					>
						Baixar
					</span>
				</div>
			</div>

			<hr>
		</div>
	</div>
</template>

<script>
import UIImage from '@components/UIImage';
import { downloadFile } from '@commons/helpers.js';
import { getLocaleDate } from '@commons/date.js';

const numberFormatter = new Intl.NumberFormat('pt-BR', {
	maximumFractionDigits: 2,
}).format;

const calculateSize = file => {
	const letters = 'KMGT';

	let base = 1;
	let unit = '';

	for (let i = 0; i < 4; i++) {
		let nextBase = base * 1024;

		if (file >= nextBase) {
			base = nextBase;
			unit = letters.charAt(i);
		}
	}

	return numberFormatter(file / base) + ' ' + unit + 'B';
};

const isAluno = usua_login => String(usua_login).substring(0, 3) === 'alu';

export default {
	name: 'DetalheInteracao',

	components: {
		UIImage,
	},

	props: {
		interacao: {
			type: [
				Object,
				Boolean
			],
			required: true,
		},

		requId: {
			type: Number,
			required: true,
		},
	},

	computed: {
		imagemUrl() {
			if (isAluno(this.interacao.usua_login)) {
				return this.$store.getters['aluno/dadosPessoais/foto'];
			}

			return 'person_no_picture.svg';
		},

		nome() {
			if (isAluno(this.interacao.usua_login)) {
				return this.$store.state.alunoNome;
			}

			return this.interacao.usua_login;
		},

		data() {
			return getLocaleDate(this.interacao.data_hora);
		},

		anexos() {
			const anexos = [];

			if (!this.interacao.anexos || !this.interacao.anexos.length) {
				return anexos;
			}

			for (const anexo of this.interacao.anexos) {
				anexos.push({
					'id': anexo.rean_id,
					'tamanho': calculateSize(anexo.rean_tamanho),
				});
			}

			return anexos;
		}
	},

	methods: {
		async download(rean_id) {
			try {
				this.$store.dispatch('ui/toast/openInfo', 'Iniciando o download');

				const anexo = await this.$store.dispatch('solicitacoes/obterArquivo', { requ_id: this.requId, rean_id });

				downloadFile(
					anexo.data,
					rean_id + '.' + anexo.extensao,
					anexo.mime
				);
			} catch (e) {
				this.$store.dispatch('ui/toast/openError', 'Não foi possível baixar esse anexo, tente novamente');
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.detail-iteration {
	padding: 0 1rem;
	display: flex;
	flex-direction: column;
	color: #525252;

	.iteration-header {
		display: flex;
		gap: 1rem;

		&-image {
			width: 36px;
			height: 36px;
			margin: 10px 0;
			overflow: hidden;
			border-radius: 50%;
		}

		&-group {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;

			.b-skeleton {
				margin: 0.25rem 0 !important;
			}

			.iteration-user {
				font-weight: bold;
			}
		}
	}

	.iteration-main {
		margin-top: 1rem;
	}

	.iteration-footer {
		margin-top: 2.5rem;
		margin-left: 1.5rem;
		display: flex;
		flex-direction: column;
		font-size: 0.875rem;

		.iteration-download {
			margin-left: 1rem;
			cursor: pointer;
		}
	}

	hr {
		height: 1px;
		margin: 1rem 0;
		background-color: #C2C2C2;
	}
}
::v-deep {
	.iteration-main {
		margin-top: 1rem;
		word-wrap: break-word;

		div ul {
			list-style: disc !important;	
		}

		div {
			ul {
				list-style: disc !important;
				margin-left: 1rem !important;
			}
			ol {
				list-style: decimal !important;
				margin-left: 1rem !important;
			}
		}

		p {
			margin: 0 0 10px;
		}
		
	}
}
</style>
