<template>
	<div class="card-content">
		<div class="content">
			<div>
				<h1 class="has-text-centered primary-color">
					Como você avalia o nosso atendimento?
				</h1>
			</div>

			<div class="is-flex is-flex-direction-column is-justify-content-center mt-5">
				<div class="is-flex is-justify-content-center mt-3">
					<span class="is-flex-direction-column is-flex has-text-centered is-justify-content-flex-start mx-2">
						<IconButton 
							file="solicitacao/one-disabled" 
							file-hover="solicitacao/one-disabled-big" 
							file-active="solicitacao/one-enabled"
							file-active-hover="solicitacao/one-enabled-big" 
							:active="atendimento.nota == 1" 
							@click="atendimento.nota == 1?atendimento.nota = null:atendimento.nota = 1" 
						/>
						<small>Ruim</small>
					</span>
					<span class="is-flex-direction-column is-flex has-text-centered is-justify-content-flex-start mx-2">
						<IconButton 
							file="solicitacao/two-disabled" 
							file-hover="solicitacao/two-disabled-big" 
							file-active="solicitacao/two-enabled"
							file-active-hover="solicitacao/two-enabled-big" 
							:active="atendimento.nota == 2" 
							@click="atendimento.nota == 2?atendimento.nota = null:atendimento.nota = 2" 
						/>
						<small>Não gostei</small>
					</span>
					<span class="is-flex-direction-column is-flex has-text-centered is-justify-content-flex-start mx-2">
						<IconButton 
							file="solicitacao/three-disabled" 
							file-hover="solicitacao/three-disabled-big" 
							file-active="solicitacao/three-enabled"
							file-active-hover="solicitacao/three-enabled-big" 
							:active="atendimento.nota == 3" 
							@click="atendimento.nota == 3?atendimento.nota = null:atendimento.nota = 3" 
						/>
						<small>Sei lá</small>
					</span>
					<span class="is-flex-direction-column is-flex has-text-centered is-justify-content-flex-start mx-2">
						<IconButton 
							file="solicitacao/four-disabled" 
							file-hover="solicitacao/four-disabled-big" 
							file-active="solicitacao/four-enabled"
							file-active-hover="solicitacao/four-enabled-big" 
							:active="atendimento.nota == 4" 
							@click="atendimento.nota == 4?atendimento.nota = null:atendimento.nota = 4" 
						/>
						<small>Gostei</small>
					</span>
					<span class="is-flex-direction-column is-flex has-text-centered is-justify-content-flex-start mx-2">
						<IconButton 
							file="solicitacao/five-disabled" 
							file-hover="solicitacao/five-disabled-big" 
							file-active="solicitacao/five-enabled"
							file-active-hover="solicitacao/five-enabled-big" 
							:active="atendimento.nota == 5" 
							@click="atendimento.nota == 5?atendimento.nota = null:atendimento.nota = 5" 
						/>
						<small>Adorei</small>
					</span>
				</div>
			</div>
			<div class="is-flex is-flex-direction-column is-justify-content-center mt-5">
				<span class="has-text-weight-bold has-text-centered">
					<Icon 
						class="icon-text mr-2" 
						file="solicitacao/target-arrow" 
						size="20" 
					/>
					Sua solicitação foi resolvida?*
				</span>
				<div class="is-flex is-justify-content-center mt-3">
					<IconButton 
						class="mr-5" 
						file="solicitacao/like-disabled" 
						file-active="solicitacao/like-enabled"
						file-active-hover="solicitacao/like-enabled-big" 
						file-hover="solicitacao/like-disabled-big" 
						:width="140" 
						:active="atendimento.resolvido === true" 
						@click="atendimento.resolvido = true"
					/>
					<IconButton 
						file="solicitacao/dislike-disabled" 
						file-active="solicitacao/dislike-enabled" 
						file-active-hover="solicitacao/dislike-enabled-big" 
						file-hover="solicitacao/dislike-disabled-big" 
						:width="140"
						:active="atendimento.resolvido === false" 
						@click="atendimento.resolvido = false" 
					/>
				</div>
			</div>
			<div class="is-flex is-flex-direction-column is-justify-content-center mt-5">
				<span class="has-text-weight-bold has-text-centered">
					<Icon 
						class="icon-text mr-2" 
						file="solicitacao/clock" 
						size="20" 
					/>
					O tempo de resposta atendeu sua necessidade?*
				</span>
				<div class="is-flex is-justify-content-center mt-3">
					<IconButton 
						class="mr-5" 
						file="solicitacao/like-disabled" 
						file-active="solicitacao/like-enabled"
						file-active-hover="solicitacao/like-enabled-big" 
						file-hover="solicitacao/like-disabled-big" 
						:width="140" 
						:active="atendimento.tempo === true" 
						@click="atendimento.tempo = true" 
					/>
					<IconButton 
						file="solicitacao/dislike-disabled" 
						file-active="solicitacao/dislike-enabled" 
						file-active-hover="solicitacao/dislike-enabled-big" 
						file-hover="solicitacao/dislike-disabled-big" 
						:width="140"
						:active="atendimento.tempo === false" 
						@click="atendimento.tempo = false" 
					/>
				</div>
			</div>
			<div
				class="column is-8-desktop is-8-fullhd is-12-mobile is-10-tablet is-offset-1-tablet is-offset-2-desktop is-offset-2-fullhd"
			>
				<p>Deixe um comentário (não obrigatório):</p>
				<b-field>
					<b-input 
						v-model="atendimento.feedback" 
						maxlength="200" 
						type="textarea" 
						rows="1" 
						:has-counter="false"
						resize="false" 
					/>
				</b-field>
			</div>

			<div 
				class="column is-4-desktop is-4-fullhd is-12-mobile is-5-tablet is-offset-6-tablet is-offset-6-desktop is-offset-6-fullhd mt-3 is-flex is-justify-content-end is-align-items-center"
				:class="{ 'is-flex-direction-column': isMobile }"
			>
				<span 
					class="primary-color has-text-weight-bold is-clickable" 
					:class="{
						'mr-6': !isMobile,
						'mb-4': isMobile
					}"
					@click="$emit('skipFeedback')"
				>Pular</span>

				<button 
					:disabled="!validateFields" 
					type="submit" 
					class="button is-primary is-rounded"
					@click="validateFields ? $emit('sendFeedback', atendimento) : false"
				>
					Enviar avaliação
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import IconButton from '@components/solicitacoes/modal/IconButton'
import Icon from '@components/Icon'

export default {
	name: 'AcompanhamentoFeedbackModal',
	components: { IconButton, Icon },
	props: {
		requId: {
			type: [Number, String],
			default: 0
		},

		row: {
			type: Object,
			default: () => { }
		}
	},

	data() {
		return {
			atendimento: {
				nota: null,
				resolvido: null,
				tempo: null,
				feedback: '',
			},

		}
	},

	computed: {
		isModalActive: {
			get() {
				return this.getOpenFeedbackModal
			},
		},

		...mapGetters('ui', {
			isMobile: 'isMobile',
		}),

		...mapGetters('solicitacoes', [
			'listagem',
			'getOpenFeedbackModal',
		]),

		validateFields() {
			return Object.values(this.atendimento).every(o => o !== null && o !== undefined);
		}
	},

	methods: {
		...mapMutations('solicitacoes', [
			'setOpenModalFeedback',
			'setLoading'
		]),

		
	}

}
</script>

<style lang="scss" scoped>
::v-deep {

	.b-radio.radio input[type=radio]+.check:before {
		background-color: var(--primary);
	}

	.b-radio.radio input[type=radio]:checked+.check {
		border-color: var(--primary);
	}

	.b-radio.radio:hover input[type=radio]+.check {
		border-color: var(--primary);
	}

	.rate .rate-item.set-on .icon,
	.rate .rate-item.set-half .is-half {
		color: var(--primary);
	}

	.rate-item {
		margin: 0px 6px !important;
	}

	.mdi-48px.mdi-set,
	.mdi-48px.mdi:before {
		font-size: 52px !important;
	}


	textarea {
		resize: none !important;
		background-color: #CCC;
	}

	textarea:focus,
	textarea:active {
		border-color: transparent !important;
	}

	.modal-content::-webkit-scrollbar {
		display: none;
	}
}

.icon-text {
	display: inline-block;
}

.card {
	max-width: 90% !important;
	margin: 0px 5%;
}

.rate-box {
	width: fit-content;
}

.label-rate {
	width: 250px;
}
</style>
