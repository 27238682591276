<template>
	<div class="columns">
		<transition name="fade">
			<div
				class="column is-10-tablet is-offset-1-tablet is-10-fullhd"
			>
				<Title>
					Solicitações
				</Title>

				<div
					class="panel"
				>
					<div class="panel-header">
						<b-tabs v-model="activeTab">
							<b-tab-item label="Nova Solicitação" />

							<b-tab-item label="Acompanhamento de Solicitações" />

							<b-tab-item
								label="Detalhes"
								:visible="false"
							/>

							<template #end>
								<span
									v-show="activeTab === 2"
									class="go-back"
									@click="goToTab(1)"
								>
									Voltar
								</span>
							</template>
						</b-tabs>

						<transition name="fade">
							<div
								v-if="activeTab == 0"
								:class="{ 'panel-content': !isMobile }"
							>
								<NovaSolicitacao
									@go-to-tab="goToTab($event)"
								/>
							</div>
						</transition>
					</div>

					<transition name="fade">
						<div
							v-if="activeTab == 1"
							class="panel-content"
						>
							<Acompanhamento
								@createNew="goToTab(0)"
								@showDetailed="showDetailed"
							/>
						</div>
					</transition>

					<transition name="fade">
						<div v-if="activeTab == 2">
							<Detalhe
								:id="detailedId"
								@createNew="goToTab(0)"
							/>
						</div>
					</transition>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import Title from '@components/Title';
import NovaSolicitacao from '@components/solicitacoes/NovaSolicitacao';
import Acompanhamento from '@components/solicitacoes/Acompanhamento';
import Detalhe from '@components/solicitacoes/Detalhe';
import { mapActions, mapGetters, mapMutations } from 'vuex';


export default {
	name: 'Solicitacoes',

	components: {
		Title,
		NovaSolicitacao,
		Acompanhamento,
		Detalhe,
	},

	data() {
		return {
			activeTab: 0,
			detailedId: 0,
			ultimoVinculoSelecionado: null,
			curs_id: null,
		};
	},

	computed: {
		...mapGetters('solicitacoes', [
			'isLoading',
			'listagem',
			'getSecoes'
		]),
		...mapGetters('ui', [
			'isMobile',
		]),
		...mapGetters('vinculosAcademicos', [
			'getVinculoSelecionado'
		]),
		getActiveTab() {
			return this.activeTab;
		},

		cursos() {
			return this.$store.getters['cursos/getCursos'];
		},

		isLoadSecoesAndCursos() {
			return this.cursos?.length > 0 && this.getSecoes?.length > 0;
		},

		isLoadListagemAndCurso() {
			return this.listagem?.length > 0 && this.cursos?.length > 0;
		}
	},

	watch: {
		isLoadListagemAndCurso(value) {
			if (value) {
				const { requ_id } = this.$route.query;

				if (requ_id) {
					this.openDetailsByRedirect(requ_id);
				}
			}
		},

		isLoadSecoesAndCursos(value) {
			if (value) {
				const { reqt_id } = this.$route.query;

				if (reqt_id) {
					this.setFiltersSolicitacao(reqt_id);
				}
			}
		},

		cursos(cursos) {
			if (cursos != null && cursos != undefined && cursos.length > 0) {
				const { curs_id } = this.$route.query;

				if (curs_id) {
					this.setVinculoByCourse(curs_id)
				}
			}
		}
	},

	async created() {
		this.$store.dispatch('ui/loading', true);

		const { requ_id, reqt_id, curs_id } = this.$route.query;

		if ((reqt_id || requ_id) && curs_id) {
			this.setVinculoSelecionado('Todos os Vínculos');
		}

		try {
			await this.$store.dispatch('solicitacoes/carregar');

			if (requ_id) {
				this.goToTab(1)
			}

			if (reqt_id) {
				this.goToTab(0);
				return;
			}

			if (this.$store.getters['solicitacoes/temAberto']) {
				this.activeTab = 1;
			}

		} catch (e) {
			this.$store.dispatch('ui/toast/openError', String(e));
		} finally {
			this.$store.dispatch('ui/loading', false);
		}
	},

	methods: {
		...mapMutations('vinculosAcademicos', [
			'setVinculoSelecionado'
		]),

		...mapMutations('solicitacoes', [
			'setSecaoSelecionada',
			'setCategoriaSelecionada',
			'setTipoSelecionado',
		]),

		...mapActions('solicitacoes', [
			'carregaTipoDados'
		]),

		goToTab(index) {
			this.activeTab = index;
		},

		showDetailed(id) {
			this.detailedId = id;

			this.goToTab(2);
		},
		openDetailsByRedirect(requ_id) {
			const requerimento = this.listagem.find(requerimento => requerimento.requ_id == requ_id)

			if (requerimento && requerimento.reqs_descricao_web.toLowerCase() != 'aguardando pagamento') {
				this.showDetailed(parseInt(requ_id))
				return;
			}

			this.$buefy.toast.open({
				message: 'Requerimento não disponível para acompanhamento.',
				type: 'is-danger',
				duration: 4000
			});
		},
		setFiltersSolicitacao(reqt_id) {
			let secaoFiltrada = null;
			let categoriaFiltrada = null;
			let tipoFiltrado = null;

			const findSecao = this.getSecoes.find(secao => {
				const findCategoria = secao.categorias.find(categoria => {

					const findTipo = categoria.tipos.find(tipo => {
						return tipo.reqt_id == reqt_id
					});

					if (findTipo) {
						tipoFiltrado = findTipo;
						categoriaFiltrada = categoria;
						return true;
					}

					return false;
				})

				if (findCategoria) {
					categoriaFiltrada = findCategoria;
					return findCategoria;
				}

				return false;
			})

			if (findSecao) {
				secaoFiltrada = findSecao;
			}

			this.setSecaoSelecionada(secaoFiltrada);
			this.setCategoriaSelecionada(categoriaFiltrada);
			this.setTipoSelecionado(tipoFiltrado);
			this.carregaTipoDados();
		},

		setVinculoByCourse(curs_id) {
			if (curs_id != null && curs_id != undefined) {
				if (this.cursos != undefined && this.cursos.length > 0) {
					this.setVinculoSelecionado(this.cursos.find(curso => curso.curs_id == curs_id));
				}
			}
		}
	},
};
</script>

<style lang="scss" scoped>
.go-back {
	align-items: center;
	color: #000;
	cursor: pointer;
	display: flex;
	font-weight: 700;
	justify-content: center;
	padding: 8px 16px;
	vertical-align: top;
}
</style>
