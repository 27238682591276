<template>
	<div>
		<div class="detail-info">
			<div class="info-group">
				<span class="info-name">Solicitante</span>

				<div class="info-value">
					<b-skeleton
						v-if="!solicitacao"
						animated
					/>

					<span v-else>{{ nome }}</span>
				</div>
			</div>

			<div class="info-group">
				<span class="info-name">Curso</span>

				<div class="info-value">
					<b-skeleton
						v-if="!solicitacao"
						animated
					/>

					<span v-else>{{ solicitacaoBase.curs_nome }}</span>
				</div>
			</div>

			<div class="info-group">
				<span class="info-name">Criado</span>

				<div class="info-value">
					<b-skeleton
						v-if="!solicitacao"
						animated
					/>

					<span v-else>{{ criada }}</span>
				</div>
			</div>

			<hr>

			<div class="info-group">
				<span class="info-name">Última atividade</span>

				<div class="info-value">
					<b-skeleton
						v-if="!solicitacao"
						animated
					/>

					<span v-else>{{ dataUltimaMovimentacao }}</span>
				</div>
			</div>

			<div class="info-group">
				<span class="info-name">Previsão de resposta</span>

				<div class="info-value">
					<b-skeleton
						v-if="!solicitacao"
						animated
					/>

					<span v-else>{{ solicitacao.requ_data_resposta }}</span>
				</div>
			</div>

			<hr>

			<div class="info-group">
				<span class="info-name">Em atendimento por</span>

				<div class="info-value">
					<b-skeleton
						v-if="!solicitacao"
						animated
					/>

					<span v-else>{{ atendente }}</span>
				</div>
			</div>

			<div class="info-group">
				<span class="info-name">ID do requerimento</span>

				<div class="info-value">
					<b-skeleton
						v-if="!solicitacao"
						animated
					/>

					<span v-else>{{ solicitacao.requ_requerimento }}</span>
				</div>
			</div>

			<div class="info-group">
				<span class="info-name">Status</span>

				<div class="info-value">
					<b-skeleton
						v-if="!solicitacao"
						animated
					/>

					<span v-else>{{ solicitacaoBase.reqs_descricao_web }}</span>
				</div>
			</div>

			<div class="info-group">
				<span class="info-name">Categoria</span>

				<div class="info-value">
					<b-skeleton
						v-if="!solicitacao"
						animated
					/>

					<span v-else>{{ solicitacao.rqsc_nome }}</span>
				</div>
			</div>

			<div class="info-group">
				<span class="info-name">Subcategoria</span>

				<div class="info-value">
					<b-skeleton
						v-if="!solicitacao"
						animated
					/>

					<span v-else>{{ solicitacao.reca_nome }}</span>
				</div>
			</div>

			<div class="info-group">
				<span class="info-name">Nome do requerimento</span>

				<div class="info-value">
					<b-skeleton
						v-if="!solicitacao"
						animated
					/>

					<span v-else>{{ solicitacao.reqt_descricao }}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getLocaleDateWithTime } from '@commons/date';

export default {
	name: 'DetalheInfo',

	props: {
		solicitacao: {
			type: [
				Object,
				Boolean
			],
			required: true,
		},
	},

	computed: {
		solicitacaoBase() {
			return this.$store.getters['solicitacoes/listagem']
				.find(s => s.requ_requerimento == this.solicitacao.requ_requerimento);
		},

		nome() {
			return this.$store.state.alunoNome;
		},

		criada() {
			return getLocaleDateWithTime(this.solicitacao.requ_data_solicitacao);
		},

		dataUltimaMovimentacao() {
			const [ movimentacao ] = this.solicitacao.movimentos.slice(-1);

			return getLocaleDateWithTime(movimentacao.data_hora);
		},

		atendente() {
			const movimentacoes = this.solicitacao.movimentos.slice(1);

			if (!movimentacoes.length) {
				return '';
			}

			const [ ultimaMovimentacao ] = movimentacoes.slice(-1);

			return ultimaMovimentacao.usua_login;
		},
	}
}
</script>

<style lang="scss" scoped>
.detail-info {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	background-color: #999;
	color: #fff;

	.info-group {
		display: flex;
		gap: 1rem;

		.info-name,
		.info-value {
			flex: 1 0;
		}

		.info-name {
			font-weight: 700;
		}
	}

	hr {
		height: 1px;
		margin: 0;
		background-color: #fff;
	}
}
</style>
