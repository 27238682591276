<template>
	<div class="container">
		<div
			v-if="isVinculoValido"
			class="columns is-flex is-align-items-center is-flex-direction-column is-mobile"
		>
			<div class="mt-6 column is-8-desktop is-10-mobile">
				<h4 class="grey-text is-size-6 has-text-weight-bold">
					{{ getVinculoSelecionado | nomeVinculoSelecionado }}
				</h4>
			</div>

			<div class="column is-10-desktop is-12-mobile is-12-tablet">
				<UIInput
					id="categoria-solicitacao"
					v-model="secaoSelecionada"
					label="Escolha a categoria:"
					type="select"
					placeholder="Selecione a categoria"
					:options="secoes"
				/>
			</div>

			<div class="column is-10-desktop is-12-mobile is-12-tablet">
				<UIInput
					id="categoria-subcategoria"
					v-model="categoriaSelecionada"
					label="Qual a subcategoria?"
					type="select"
					placeholder="Selecione a subcategoria"
					:options="categorias"
				/>
			</div>

			<div class="column is-10-desktop is-12-mobile is-12-tablet">
				<UIInput
					id="tipo-solicitacao"
					v-model="tipoSelecionado"
					label="Qual sua solicitação?"
					type="select"
					placeholder="Selecione o tipo"
					:options="tipos"
				/>
			</div>

			<transition name="fade">
				<div
					v-show="getTipoSelecionado != null"
					class="column is-10-desktop is-12-mobile is-12-tablet"
				>
					<h1 class="ml-2 px-3 mb-4 has-text-weight-bold">
						Orientação deste requerimento:
					</h1>

					<div
						v-if="getTipoSelecionado != null && isLoading"
						class="px-3 mb-4 is-size-7"
					>
						<b-skeleton
							active
							height="4.5rem"
						/>
					</div>

					<p
						v-else
						id="reqt_descricao"
						class="px-3 mb-4 is-size-7"
						v-html="getOrientacaoRequerimento"
					/>
				</div>
			</transition>

			<transition name="fade">
				<div
					v-if="getTipoSelecionado != null && getTipoSelecionado.reqt_campo_extra == 't'"
					class="column is-10-desktop is-12-mobile is-12-tablet"
				>
					<UIInput
						id="label-extra"
						v-model="informacaoExtra"
						:label="getTipoSelecionado.reqt_label_extra"
						type="text"
						placeholder=""
					/>
				</div>
			</transition>

			<transition name="fade">
				<div
					v-if="getTipoSelecionado != null && getTipoSelecionado.reqt_permite_escolha_disciplinas == 't'"
					class="column is-10-desktop is-12-mobile is-12-tablet"
				>
					<ListaDisciplinasNaoDisponiveis
						:limite="getTipoSelecionado.reqt_qtd_disciplinas"
						@naoEncontrei="showMotivoDisciplina = $event"
					/>
				</div>
			</transition>

			<transition name="fade">
				<div
					v-show="exibeMotivo && !isLoading"
					:key="motivoPlaceholder"
					class="column is-10-desktop is-12-mobile is-12-tablet"
				>
					<UIInput
						id="motivo-solicitacao"
						v-model="motivoSolicitacao"
						:placeholder="motivoPlaceholder"
						type="textarea"
						:minheight="156"
						:maxheight="156"
					/>
				</div>
			</transition>

			<div class="column is-10-desktop is-12-mobile is-12-tablet">
				<div class="bordered px-4 py-4">
					<div class="columns">
						<div class="column is-6-desktop is-12-mobile is-6-tablet is-flex is-flex-direction-column is-justify-content-space-between">
							<div
								class="is-align-items-flex-end box-input"
								:class="{ 'is-flex': !isMobile }"
							>
								<span class="box-label has-text-weight-bold">Seu email:</span>
								<b-input
									v-if="enableInputAlteracao"
									v-model="form.email"
									:custom-class="'input-altera-dados'"
								/>
								<span
									v-else
									:class="{ 'email-label': isMobile }"
								>{{ form.email }}</span>
							</div>

							<div class="is-flex is-align-items-flex-end box-input">
								<span class="box-label has-text-weight-bold">Seu fone:</span>
								<b-input
									v-if="enableInputAlteracao"
									v-model="form.celular"
									:custom-class="'input-altera-dados'"
								/>
								<span v-else>{{ form.celular }}</span>
							</div>
						</div>

						<div
							class="column is-6-desktop is-12-mobile is-6-tablet is-flex is-justify-content-center is-align-items-center"
							:class="{
								'is-flex-direction-column': isMobile
							}"
						>
							<button
								v-if="!enableInputAlteracao"
								type="submit"
								class="button is-primary is-rounded is-outlined"
								@click="enableInputAlteracao = true"
							>
								Alterar dados
							</button>

							<div
								class="is-flex is-justify-content-space-between"
								:class="{
									'is-flex-direction-column': isMobile
								}"
							>
								<div>
									<button
										v-if="enableInputAlteracao"
										type="submit"
										class="button is-primary is-rounded is-outlined"
										:class="{
											'mb-3': isMobile,
										}"
										@click="resetData"
									>
										Voltar
									</button>
								</div>

								<div>
									<button
										v-if="enableInputAlteracao"
										type="submit"
										class="button is-primary is-rounded"
										@click="saveData"
									>
										Salvar alteração
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="column is-10 is-flex is-justify-content-space-between"
				:class="{ 'is-flex-direction-column': isMobile }"
			>
				<div
					class="column px-0 is-6-desktop is-12-mobile is-12-tablet is-flex is-flex-direction-column"
					:class="{ 'is-align-items-center': isMobile }"
				>
					<b-field
						class="file is-primary"
						:class="[
							{ 'has-name': !!files },
							{ 'pl-5': isMobile }
						]"
					>
						<b-upload
							v-model="files"
							multiple
						>
							<span
								class="button is-primary is-outlined is-rounded"
							>
								<span class="px-6">
									Anexar
								</span>
							</span>
						</b-upload>
					</b-field>

					<p class="pt-4 is-size-7 grey-text">
						Seus documentos devem ter o tamanho máximo de 15 Mega e formato “.PDF”, “.PNG”, “.JPEG” ou “.ZIP”.
					</p>

					<span
						v-if="anexoObrigatorio"
						class="pt-5 is-size-7 required-text"
					>
						*Anexo obrigatório
					</span>

					<div
						v-if="files.length > 0"
						class="column is-12"
					>
						<p
							v-for="file, index in files"
							:key="index"
							class="is-size-7 overflow-text file-attachment"
							@click="deleteFile(index)"
						>
							<b-icon
								icon="circle-medium"
								size=""
								custom-class="delete-file"
							/>

							<span>{{ file.name }}</span>
						</p>
					</div>
				</div>

				<div
					v-if="getValorCobranca > 0"
					class="column is-6-desktop is-12-mobile is-12-tablet px-0 is-flex is-flex-direction-column"
					:class="[isMobile ? 'is-align-items-center' : 'is-align-items-flex-end']"
				>
					<div class="is-flex is-align-items-center">
						<Icon
							class="pr-2 is-flex"
							file="dolar"
							size="18px"
						/>
						<span>Custo da solicitação:</span>
					</div>

					<p class="is-size-3 grey-text has-text-weight-bold">
						{{ getValorCobranca | valorPTBR }}
					</p>
				</div>
			</div>

			<div class="column is-10-desktop is-12-mobile is-12-tablet px-0 is-flex is-justify-content-flex-end">
				<div
					class="column px-0 is-5-desktop is-12-mobile is-12-tablet is-flex"
					:class="[isMobile ? 'is-justify-content-center' : 'is-justify-content-flex-end']"
				>
					<button
						type="submit"
						class="button is-primary is-rounded"
						:disabled="isLoading"
						@click="handleModal"
					>
						Enviar Solicitação
					</button>
				</div>
			</div>
		</div>

		<div v-else>
			<div class="mb-5 is-flex is-flex-direction-column is-align-items-center">
				<Icon
					class="
					primary-color"
					file="pendencias_grad"
					size="100%"
				/>

				<h1 class="pb-5 is-size-4 has-text-centered">
					Para realizar a abertura de um requerimento, é obrigatório a <br> seleção de um vínculo no filtro acima!
				</h1>
			</div>
		</div>


		<!-- MODAL CONTINUIDADE SOLICITACAO -->
		<NovaSolicitacaoModal
			@go-to="handleGoTo"
		/>

		<!-- MODAL ATUALIZACAO DADOS -->
		<NovaSolicitacaoModalAtualizaDados
			:open-modal="openModalAtualizaDados"
			:celular-antes="getCelular"
			:celular-depois="form.celular"
			:email-antes="getEmail"
			:email-depois="form.email"
			@close="openModalAtualizaDados = false"
		/>
	</div>
</template>

<script>
import UIInput from '@components/uiinput';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { capitalizeAll } from '@commons/helpers';
import { isString, isNil } from 'lodash';
import Icon from '@components/Icon';
import NovaSolicitacaoModal from '@components/solicitacoes/modal/NovaSolicitacaoModal';
import NovaSolicitacaoModalAtualizaDados from '@components/solicitacoes/modal/NovaSolicitacaoModalAtualizaDados';
import ListaDisciplinasNaoDisponiveis from '@components/solicitacoes/nova/ListaDisciplinasNaoDisponiveis';

const maxFileSize = 15105151;

const acceptedImageTypes = [
	'image/jpg',
	'image/jpeg',
	'image/png',
	'application/x-zip-compressed',
	'application/zip',
	'application/pdf',
];

export default {
	name: 'NovaSolicitacao',
	components: {
		UIInput,
		Icon,
		NovaSolicitacaoModal,
		NovaSolicitacaoModalAtualizaDados,
		ListaDisciplinasNaoDisponiveis,
	},
	filters: {
		nomeVinculoSelecionado(value) {
			if (isString(value)) {
				return "";
			}

			return `${capitalizeAll(value.nens_nome)}: ${capitalizeAll(value.curs_nome)}`;
		},

		valorPTBR(value) {
			return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
		}
	},
	data() {
		return {
			enableInputAlteracao: false,
			openModalAtualizaDados: false,
			form: {
				email: '',
				celular: '',
			},
			informacaoExtra: null,
			showMotivoDisciplina: false,
		};
	},
	computed: {
		...mapGetters('vinculosAcademicos', [
			'getVinculoSelecionado'
		]),

		...mapGetters('solicitacoes', [
			'contemCobranca',
			'getSecoes',
			'getSecaoSelecionada',
			'getCategoriaSelecionada',
			'getTipoSelecionado',
			'getOrientacaoRequerimento',
			'getValorCobranca',
			'getMotivoSolicitacao',
			'getFiles',
			'getDisciplinasNaoDisponiveisSelecionadas'
		]),

		...mapGetters('ui', [
			'isMobile',
			'isLoading'
		]),

		...mapGetters('aluno/dadosPessoais', [
			'getEmail',
			'getCelular'
		]),

		secoes() {
			if (!isNil(this.getSecoes)) {
				return this.getSecoes.map(secao => ({ label: capitalizeAll(secao.rqsc_nome), value: secao.rqsc_id }));
			}

			return null;
		},

		categorias() {
			if (!isNil(this.getSecaoSelecionada)) {
				const secao = this.getSecaoSelecionada;

				return secao.categorias.map(categoria => ({ label: capitalizeAll(categoria.reca_nome), value: categoria.reca_id }));
			}

			return null;
		},

		tipos() {
			if (!isNil(this.getCategoriaSelecionada)) {
				const categoria = this.getCategoriaSelecionada;

				return categoria.tipos.map(tipo => ({ label: capitalizeAll(tipo.reqt_descricao), value: tipo.reqt_id }));
			}

			return null;
		},

		motivoSolicitacao: {
			get() {
				return this.getMotivoSolicitacao;
			},

			set(value) {
				this.setMotivoSolicitacao(value);
			}
		},

		exibeMotivo() {
			if (this.getTipoSelecionado == null) {
				return false;
			}

			if (this.getTipoSelecionado.reqt_permite_escolha_disciplinas != 't') {
				return true;
			}

			return this.showMotivoDisciplina;
		},

		motivoPlaceholder() {
			if (this.getTipoSelecionado?.reqt_permite_escolha_disciplinas != 't') {
				return 'Conte-nos o motivo ou mais detalhes de sua solicitação';
			}

			if (this.getVinculoSelecionado.nens_id == 1) {
				return 'Descreva qual disciplina/UC\'s não estava disponível para a inscrição';
			}

			return 'Descreva qual disciplina não estava disponível para a inscrição';
		},

		files: {
			get() {
				return this.getFiles;
			},

			set(files) {
				const filesFiltered = files.filter(file => {
					if (file.size > maxFileSize) {
						this.$buefy.toast.open({
							duration: 5000,
							message: `O tamanho do arquivo ${file.name} excede os 15MB permitidos!`,
							position: 'is-top',
							type: 'is-danger'
						});

						return false;
					}

					if (!acceptedImageTypes.includes(file.type)) {
						this.$buefy.toast.open({
							duration: 5000,
							message: `O arquivo ${file.name} não é compativel com os tipos permitidos!`,
							position: 'is-top',
							type: 'is-danger'
						});

						return false;
					}

					return true;
				});

				this.setFiles(filesFiltered);
			}
		},

		anexoObrigatorio() {
			return this.getTipoSelecionado != null && this.getTipoSelecionado.reqt_anexo_obrigatorio == 't';
		},

		isVinculoValido() {
			return !isString(this.getVinculoSelecionado) && !isNil(this.getVinculoSelecionado);
		},

		secaoSelecionada: {
			get() {
				return this.getSecaoSelecionada?.rqsc_id || null;
			},

			set(value) {
				this.setSecaoSelecionada(this.getSecoes.filter(secao => secao.rqsc_id === value)[0]);
				this.setCategoriaSelecionada(null);
				this.setTipoSelecionado(null);
				this.informacaoExtra = null;
			}
		},

		categoriaSelecionada: {
			get() {
				return this.getCategoriaSelecionada?.reca_id || null;
			},

			set(value) {
				if (isNil(value)) {
					return;
				}

				this.setTipoSelecionado(null);
				const categoria = this.getSecaoSelecionada.categorias.filter(categoria => categoria.reca_id == value)[0];
				this.setCategoriaSelecionada(categoria);
				this.informacaoExtra = null;
			}
		},

		tipoSelecionado: {
			get() {
				return this.getTipoSelecionado?.reqt_id || null;
			},

			async set(value) {
				if (isNil(value)) {
					return;
				}

				const tipo = this.getCategoriaSelecionada.tipos.filter(tipo => tipo.reqt_id == value)[0];
				this.setTipoSelecionado(tipo);
				await this.carregaTipoDados();
				this.informacaoExtra = null;

				if (this.getTipoSelecionado.reqt_campo_extra == 't') {
					this.setLabelExtra(this.getTipoSelecionado.reqt_label_extra);
				}
			}
		},
	},
	watch: {
		async getVinculoSelecionado(value) {
			if (!isString(value)) {
				await this.carregaSecoes();
				this.loadPresetsFromURLParameters();
			}
		},

		getCelular(value) {
			this.form.celular = value;
		},

		getEmail(value) {
			this.form.email = value;
		},

		informacaoExtra(value) {
			this.setInformacaoExtra(value);
		},
	},
	async created() {
		this.setSecaoSelecionada(null);
		this.setCategoriaSelecionada(null);
		this.setTipoSelecionado(null);
		this.setFiles([]);

		this.populateDadosPessoais();

		if (!isString(this.getVinculoSelecionado)) {
			await this.carregaSecoes();

			this.loadPresetsFromURLParameters();
		}
	},
	methods: {
		...mapActions('solicitacoes', [
			'carregaSecoes',
			'carregaTipoDados'
		]),

		...mapMutations('solicitacoes', [
			'setModalActive',
			'setProgress',
			'setSecaoSelecionada',
			'setCategoriaSelecionada',
			'setTipoSelecionado',
			'setMotivoSolicitacao',
			'setFiles',
			'setInformacaoExtra',
			'setLabelExtra',
		]),

		...mapMutations('aluno/dadosPessoais', [
			'setEmail',
			'setCelular'
		]),

		...mapActions('aluno/dadosPessoais', [
			'atualizaDados'
		]),

		handleModal() {
			if (!this.validateForm()) {
				return;
			}

			if (this.contemCobranca) {
				this.setProgress(33)
			} else {
				this.setProgress(50);
			}

			this.setModalActive(true);
		},

		deleteFile(index) {
			const files = this.files.filter((_, i) => i !== index);

			this.setFiles(files);
		},

		validateForm() {
			let mensagem = '';

			if (!this.secaoSelecionada) {
				mensagem = 'Selecione a categoria';
			} else if (!this.categoriaSelecionada) {
				mensagem = 'Selecione a subcategoria';
			} else if (!this.tipoSelecionado) {
				mensagem = 'Selecione o tipo';
			} else if (!this.informacaoExtra && this.getTipoSelecionado && this.getTipoSelecionado.reqt_campo_extra == 't') {
				mensagem = `Preencha o campo: ${this.getTipoSelecionado.reqt_label_extra}`;
			} else if (
				this.getDisciplinasNaoDisponiveisSelecionadas.length == 0
				&& this.getTipoSelecionado
				&& this.getTipoSelecionado.reqt_permite_escolha_disciplinas == 't'
			) {
				mensagem = 'Selecione disciplinas';
			} else if (this.exibeMotivo && !this.motivoSolicitacao) {
				if (this.getTipoSelecionado?.reqt_permite_escolha_disciplinas != 't') {
					mensagem = 'Escreva o motivo da solicitação';
				} else {
					mensagem = this.motivoPlaceholder;
				}
			} else if (this.anexoObrigatorio && !this.files.length) {
				mensagem = 'Anexo obrigatório';
			}

			if (mensagem) {
				this.$store.dispatch('ui/toast/openError', mensagem);
			}

			return !mensagem;
		},

		handleGoTo(location) {
			if (location == 'acompanhamento') {
				this.$emit('go-to-tab', 1);
			} else if (location == 'pagamento') {
				this.$router.push({ name: 'meus-pagamentos', params: { tab: 1 } });
			}
		},

		populateDadosPessoais() {
			this.form.celular = this.getCelular;
			this.form.email = this.getEmail;
		},

		async saveData() {
			const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
			const celularRegex = /^(\d{11})/;

			if (!emailRegex.test(this.form.email)) {
				this.$buefy.toast.open({
					duration: 5000,
					message: `O Email deve ser em um formato válido!`,
					position: 'is-top',
					type: 'is-danger'
				});
				return;
			}


			if (!celularRegex.test(this.form.celular.replace(/\D/g, ''))) {
				this.$buefy.toast.open({
					duration: 5000,
					message: `O celular deve ser em um formato válido de 11 dígitos (incluindo DDD)!`,
					position: 'is-top',
					type: 'is-danger'
				});
				return;
			}

			this.enableInputAlteracao = false;
			this.openModalAtualizaDados = true;
		},

		resetData() {
			this.populateDadosPessoais();
			this.enableInputAlteracao = false;
		},

		loadPresetsFromURLParameters() {
			const { reca_id, rqsc_id } = this.$route.query;

			if (rqsc_id) {
				this.secaoSelecionada = rqsc_id;
			}

			if (reca_id) {
				this.categoriaSelecionada = reca_id;
			}
		}
	},
};
</script>

<style scoped lang="scss">
.grey-text {
	color: #777;
}

.required-text {
	color: #FF1515;
}

::v-deep {
	.ui-input>.control {
		border-color: #777777 !important;
	}

	.ui-input-select .select:not(.is-multiple):not(.is-loading)::after {
		border-color: #777777;
	}

	#motivo-solicitacao {
		font-size: 16px !important;
		padding: 18px 20px 18px 20px !important;
	}

	.box-altera-dados {
		border: 1px solid #777777 !important;
		border-radius: 5px !important;
	}

	.ui-input-textarea .control {
		padding: 0 !important;
	}

	.file-attachment {
		cursor: pointer;
		transition: all .3s ease-in;

		.icon {
			font-size: 1rem !important;
			line-height: 1rem !important;
			vertical-align: middle;
		}

		.delete-file::before {
			content: "\F09DE"; // mdi-circle-medium
		}

		&:hover {
			color: #FF1515;

			.delete-file::before {
				content: "\F0156"; // mdi-close
			}
		}
	}

	.input-altera-dados {
		background-color: transparent !important;
		outline: none !important;
		box-shadow: none !important;
		border-bottom: 1px solid #777;
		border-top: none;
		border-right: none;
		border-left: none;
		border-radius: 0px;
		padding: 0px;
		padding-left: 10px;
		padding-top: 2px;
		min-width: 100%;
		height: 19px;

	}

	.ui-input .control #label-extra {
			font-weight: 100 !important;
			height: 59px !important;
		}
	#reqt_descricao{
		word-wrap: break-word;
		ul{
			list-style: disc;
		}
		ol{
			list-style: decimal;
		}
		font-weight: 100 !important;


	}
}

.overflow-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.bordered {
	border: 1px solid #777;
	border-radius: 7px;
}

.box-label {
	width: 80px !important;
}

.box-input>.control {
	flex-grow: 1;
}


	.email-label {
		word-break: break-all;
	}


.email-label {
	word-break: break-all;
}

</style>
